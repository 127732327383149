#header-bar {
  border-bottom: 1px solid #dddddd;
}

.site-logo-cntr {
  /* height: 62px; */
  display: flex;
  align-items: flex-end;
  /* margin: 19px 0px; */
  height: 48px;
  margin: 16px;
}

.site-logo-cntr img {
  max-height: 100%;
}

.site-title {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  margin-left: 8px;
}

.merriwether {
  font-family: "Merriweather", serif;
}

.red {
  color: #ff3d00;
}

#header-bar a {
  color: #2d2d2d;
  cursor: pointer;
}
