.desktop {
  .auth-cntr {
    width: 392px;
    height: 570px;
    margin: 0 auto;
    box-shadow: 0 0 10px 7px #00000029;
    border-radius: 8px;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    position: absolute;

    .submit-btn {
      height: 48px;
      margin-top: 30px;
      margin-bottom: 20px;
    }
  }

  .register-btn {
    display: none;
  }

  .d-footer {
    display: block;
    font-size: 0.625rem;
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
  }

  .only-desktop-link {
    display: block;
  }

  .login-btn {
    color: black;
  }

  .login-link {
    display: none;
  }

  .pedgog_logo {
    margin: 30px auto 50px;
  }

  .auth-footer {
    display: none;
  }

  .auth-form-cntr {
    margin: 50px auto 0;
  }
}
.auth-form-cntr {
  margin: 4rem auto;
  max-width: 534px;
  padding: 0 45px;

  .submit-btn {
    background-color: #e7302a;
    color: #ffffff;
    margin-top: 45px;
    margin-bottom: 20px;
  }
}

.pedgog_logo {
  text-align: center;
  margin: 60px 0;
}

.only-desktop-link {
  display: none;
}

.d-footer {
  display: none;
}

.already-link {
  font-size: 0.75rem;
}

.auth-footer {
  position: absolute;
  bottom: 15px;
  display: flex;
  width: 100%;
  justify-content: center;
  text-transform: uppercase;
  font-size: 0.875rem;
}

.forgot-pw-wrapper {
  float: right;

  .forgot-pw {
    color: #b9b9b9;
    text-transform: capitalize;
    font-weight: normal;
    padding: 6px 0;
  }
}

.register-btn-wrapper {
  text-align: center;
  .register-btn,
  .login-btn {
    margin: 10px 0 20px;
    text-transform: capitalize;
    color: #b9b9b9;
    font-weight: normal;
  }
  .register-link,
  .login-link {
    color: #e7302a;
    text-transform: uppercase;
    font-weight: 500;
    padding: 0 10px;
    text-decoration: underline;
  }
}
