.feedforward-wrapper {
    overflow: auto;
    height: calc(100vh - 65px);

    &.desktop {
        width: 100%;
        .container {
            width: 510px;
            margin: 0 auto;
        }

        .form-container {
            padding: 0;
        }

        .btn-container {
            padding: 30px 0;
        }

        .ff-title {
            font-size: 1.125rem;
        }

        .note {
            font-size: 1.125rem;
            padding: 0;
            margin-top: 35px;
            margin-bottom: 0;
        }

        .save-btn-wrappper {
            display: flex;
            justify-content: center;
        }

        .save-btn {
            position: relative;
            width: 143px;
            height: 33px;
            border-radius: 17px;
            margin-top: 50px;
        }
    }

    .ff-head-title {
        font-size: 2rem;
        padding: 10px 0px 15px;
    }

    .ff-title {
        background-color: #f4f3ec;
        padding: 30px 20px;
        font-size: 1rem;
        font-weight: 500;
    }

    .form-container {
        padding: 0 20px;
    }

    .btn-container {
        padding: 30px 20px;
    }

    .addModule {
        cursor: pointer;
    }

    .practice-textfield {
        margin-top: 27px;
    }

    .note {
        padding: 0 20px;
        line-height: 1.4;
        font-weight: 300;
        margin-top: 35px;
        margin-bottom: 74px;
    }

    .save-btn {
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: 15px;
        background-color: #ffbc36;
        border-radius: 0;

        &.Mui-disabled {
            background-color: #efefef;
        }
    }
}
